import _, { partial, property, sortBy } from 'underscore';
import { createSelector } from 'reselect';

const getEntries = (state) => state.mainSettings.entries;
const getEntryName = (_state, entryName) => entryName;
const getOptions = (_state, _entryName, options) => options;
const getComponents = (state) => state.mainSettings.components;

export const getLineAndPriceChanges = createSelector(
  [getEntries], (entries) => ({
    valid: entries.acceptPriceChanges && entries.acceptLineChanges,
    acceptablePriceChangePct: entries.acceptPriceChanges?.value,
    acceptAllLineChanges: entries.acceptLineChanges?.value,
    showIfEnabled: true,
  }),
);

export const getEntry = createSelector(
  [getEntries, getEntryName, getOptions],
  (entries, name, options = {}) => {
    if (!entries[name]) return { valid: false };

    const { value, ...rest } = entries[name];

    return {
      valid: true,
      ...rest,
      [options.as || 'value']: value,
      ...(options.extra && options.extra(entries)),
    };
  },
);

export const getEntryFromState = (...args) => partial(getEntry, _, ...args);

export const getEntryValue = createSelector(
  [getEntry],
  property('value'),
);

export const getNamedValues = createSelector(
  [getEntries],
  (entries) => (
    Object.values(entries).reduce((namedValues, entry) => ({
      ...namedValues,
      [entry.name]: entry.value,
    }), {})
  ),
);

export const getComponentsList = createSelector(
  getComponents,
  (components) => sortBy(components, (component) => component.position),
);
