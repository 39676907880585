import { set as lsSet, clear as lsClear } from 'bv-local-storage';
import { getEntryValue, getNamedValues } from 'MainSettings/selectors';
import doSubmitSettings from 'MainSettings/api/submit_settings';
import handleSuccessfulUpdate from 'MainSettings/handlers/handle_successful_update';
import handleFailedUpdate from 'MainSettings/handlers/handle_failed_update';
import SubmissionError from 'MainSettings/submissionError';

const saveQuickBet = (enabled) => { lsSet('hide_quickbet', !enabled); };

const checkSuccessFlag = (response) => {
  if (!response.success) {
    throw new SubmissionError(response);
  }

  return response;
};

export default (event, state) => {
  event.preventDefault();

  lsClear();
  saveQuickBet(getEntryValue(state, 'quickbet'));

  return doSubmitSettings(getNamedValues(state))
    .then(checkSuccessFlag)
    .then(handleSuccessfulUpdate)
    .catch(handleFailedUpdate);
};
