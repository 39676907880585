import { onChange } from 'MainSettings/duck';
import handleFailedUpdate from 'MainSettings/handlers/handle_failed_update';
import { submitPreferences } from 'SharedComponents/settings/api';
import {
  settingsUpdateStart, settingsUpdateSuccess, settingsUpdateError,
} from './duck';

export const onToggle = (newValue) => (dispatch) => {
  dispatch(settingsUpdateStart());
  submitPreferences({
    'betting_settings[accept_line_change]': newValue,
  }).then(() => {
    dispatch(settingsUpdateSuccess(newValue));
    dispatch(onChange({
      target: {
        name: 'betting_settings[accept_line_change]',
        type: 'bool',
        value: newValue,
      },
    }));
  }).catch(() => {
    dispatch(settingsUpdateError());
    handleFailedUpdate();
  });
};
