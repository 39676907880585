import { compose } from 'underscore';
import { guard } from 'bv-services/features';

import doFetchSettings from 'MainSettings/api/fetch_settings';
import doSubmitSettings from 'MainSettings/services/submit';
import { get as localStorageGet } from 'bv-local-storage';
import { showQuickbetDefault } from 'global-config';

const quickBetHidden = () => (
  localStorageGet('hide_quickbet') ?? !showQuickbetDefault
);

const FETCH_SETTINGS_INIT = 'SETTINGS.MAIN.FETCH.INIT';
const FETCH_SETTINGS_COMPLETE = 'SETTINGS.MAIN.FETCH.COMPLETE';
const UPDATE_SETTING = 'SETTINGS.MAIN.UPDATE';
const SUBMIT_SETTINGS_INIT = 'SETTINGS.MAIN.SUBMIT.INIT';
const SUBMIT_SETTINGS_COMPLETE = 'SETTINGS.MAIN.SUBMIT.COMPLETE';
const LOAD_COMPONENTS_INIT = 'SETTINGS.MAIN.LOAD_COMPONENTS.INIT';
const LOAD_COMPONENTS_COMPLETE = 'SETTINGS.MAIN.LOAD_COMPONENTS.COMPLETE';
const LOAD_COMPONENT = 'SETTINGS.MAIN.LOAD_COMPONENT';

export const fetchSettingsInit = () => ({
  type: FETCH_SETTINGS_INIT,
});

export const fetchSettingsComplete = (entries) => ({
  type: FETCH_SETTINGS_COMPLETE,
  entries,
});

export const loadComponentsInit = () => ({
  type: LOAD_COMPONENTS_INIT,
});

export const loadComponentsComplete = () => ({
  type: LOAD_COMPONENTS_COMPLETE,
});

export const loadComponent = ({ name, position }) => ({
  type: LOAD_COMPONENT,
  name,
  position,
});

export const submitSettingsInit = () => ({
  type: SUBMIT_SETTINGS_INIT,
});

export const submitSettingsComplete = () => ({
  type: SUBMIT_SETTINGS_COMPLETE,
});

export const fetchSettings = (callback) => (dispatch) => {
  dispatch(fetchSettingsInit());

  doFetchSettings().then((settings) => {
    dispatch(fetchSettingsComplete(settings));
    if (typeof callback === 'function') callback();
  });
};

export const loadComponents = (componentsList) => (dispatch) => {
  const syncComponents = componentsList.filter((comp) => !comp.featureName);
  const asyncComponents = componentsList.filter((comp) => comp.featureName);
  const isLastAsyncComponent = (name) => (
    asyncComponents.findIndex((comp) => comp.name === name) === asyncComponents.length - 1
  );

  dispatch(loadComponentsInit());
  syncComponents.forEach(({ name, position }) => dispatch(loadComponent({ name, position })));

  if (!asyncComponents.length) {
    dispatch(loadComponentsComplete());
  } else {
    asyncComponents.forEach((component) => {
      const {
        name, featureName, position, reversedFeatureFlipperLogic,
      } = component;

      const completeAsyncComponentsLoadingHandler = () => {
        if (isLastAsyncComponent(name)) dispatch(loadComponentsComplete());
      };

      guard(featureName, () => {
        if (!reversedFeatureFlipperLogic) {
          dispatch(loadComponent({ name, position }));
        }

        completeAsyncComponentsLoadingHandler();
      }, () => {
        if (reversedFeatureFlipperLogic) {
          dispatch(loadComponent({ name, position }));
        }

        completeAsyncComponentsLoadingHandler();
      });
    });
  }
};

export const onChange = (event) => {
  const { target } = event;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const { name } = target;

  return {
    type: UPDATE_SETTING,
    name,
    value,
  };
};

export const submitSettings = (event) => (dispatch, getState) => {
  dispatch(submitSettingsInit());

  doSubmitSettings(event, getState())
    .then(compose(dispatch, submitSettingsComplete));
};

const initialState = {
  fetched: false,
  entries: {
    quickbet: {
      name: 'quickbet',
      value: !quickBetHidden(),
    },
  },
  submitting: false,
  components: [],
  componentsAreLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SETTINGS_INIT:
      return initialState;
    case FETCH_SETTINGS_COMPLETE:
      return {
        ...state,
        fetched: true,
        entries: {
          ...state.entries,
          ...action.entries,
        },
      };
    case UPDATE_SETTING: {
      const entry = Object.entries(state.entries)
        .find(([, value]) => value.name === action.name);

      return {
        ...state,
        entries: {
          ...state.entries,
          [entry[0]]: {
            ...entry[1],
            value: action.value,
          },
        },
      };
    }
    case SUBMIT_SETTINGS_INIT:
      return {
        ...state,
        submitting: true,
      };
    case SUBMIT_SETTINGS_COMPLETE:
      return {
        ...state,
        submitting: false,
      };
    case LOAD_COMPONENTS_INIT:
      return {
        ...state,
        componentsAreLoading: true,
      };
    case LOAD_COMPONENTS_COMPLETE:
      return {
        ...state,
        componentsAreLoading: false,
      };
    case LOAD_COMPONENT: {
      const { name, position } = action;

      return {
        ...state,
        components: state.components.concat({ name, position }),
      };
    }
    default:
      return state;
  }
};
