import { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleView from './toggle_view';
import ConfirmModalView from './confirm_modal_view';

const ToggleContainer = ({ value, loading, onToggle }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleNoBtnClick = () => {
    setShowConfirm(false);
  };

  const handleYesBtnClick = () => {
    setShowConfirm(false);
    onToggle(true);
  };

  const handleClick = () => {
    if (value) {
      onToggle(false);
    } else {
      setShowConfirm(true);
    }
  };

  return (
    <>
      {showConfirm && (
        <ConfirmModalView
          handleNoBtnClick={handleNoBtnClick}
          handleYesBtnClick={handleYesBtnClick}
        />
      )}
      <ToggleView
        checked={!!value}
        loading={loading}
        onClick={handleClick}
      />
    </>
  );
};

ToggleContainer.propTypes = {
  value: PropTypes.number.isRequired,
  loading: PropTypes.isRequired,
  onToggle: PropTypes.isRequired,
};

export default ToggleContainer;
