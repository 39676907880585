import { t } from 'bv-i18n';
import { linkLocalizer } from 'bv-helpers/locale';

const { showWarningMessage } = window.ModalHelper;

const defaultErrorMessage = () => {
  showWarningMessage({
    title: t('update_preferences_failed_title'),
    message: t('update_preferences_failed_message'),
    actions: [{
      label: t('contact_us'),
      href: linkLocalizer(t('contact_us_link')),
      warning: true,
      close: true,
    }],
    iconClosable: true,
    dataLayer: {
      error_title: t('update_preferences_failed_title'),
      error_message: t('update_preferences_failed_message'),
      error_key: 'update_preferences_failed_message',
      error_code: 'W0x001',
    },
  });
};

export default defaultErrorMessage;
